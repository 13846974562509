<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="终端名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入终端注释" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formModel.remark" placeholder="请输入备注" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNo">
          <el-input v-model="formModel.sortNo" placeholder="请输入排序号" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import terminalInfoApi from "@/api/base/terminalInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        name: [{ required: true, message: "终端名称不能为空", trigger: "blur" }],
        sortNo: [{ required: true, message: "排序号不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return terminalInfoApi.add(self.formModel);
            } else {
              return terminalInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });
              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      if (self.businessKey.length == 0) {
        return terminalInfoApi.create();
      } else {
        return terminalInfoApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>